<template>
    <div id="Announcement" class="wrap">
        <van-nav-bar
        :fixed="true"
        z-index="10"
        placeholder
        :title="$t('My.PersonalInformation.Announcement.title')"
        left-arrow
        @click-left="onClickLeft"
        />
        <van-image width="100%"  :src="require('@/assets/Img/myImg/AnnouncementBackdrop.png')" />
        <div class="content">
            <div class="Text">
                <van-image width="1.25rem" height="1.25rem"  :src="require('@/assets/Img/myImg/lingdang.png')" />
                <span class="text-icon">|</span>
                <span>{{$t('My.PersonalInformation.Announcement.System')}}</span>
            </div>
            <div class="content-item">
                <span>{{DataArr.title}}</span>
                <span>{{DataArr.content}}</span>
                <span>{{DataArr.examineTime}}</span>
            </div>
            <!-- <div class="content-item" v-for="(item,index) in DataArr" :key="index">
                <span>{{item.title}}</span>
                <span>{{item.content}}</span>
                <span>{{item.sendTime}}</span>
            </div> -->
        </div>
    </div>
</template>

<script>
    import { REQUEST_API } from "@/http/api" // 引入封装的axios
    import {Row,Col,Icon,Cell,GridItem,Grid,NavBar,ActionSheet,Button,Tab,Tabs,
        Image,NoticeBar,Pagination,Toast,}from "vant";
    export default {
        name:"Announcement",
        components: {
            [Row.name]: Row,
            [Col.name]: Col,
            [Icon.name]: Icon,
            [Cell.name]: Cell,
            [GridItem.name]: GridItem,
            [Grid.name]: Grid,
            [NavBar.name]: NavBar,
            [ActionSheet.name]: ActionSheet,
            [Button.name]: Button,
            [Image.name]:Image,
            [NoticeBar.name]:NoticeBar,
            [Tabs.name]:Tabs,
            [Tab.name]:Tab,
            [Pagination.name]:Pagination,
            [Toast.name]:Toast,
        },
        data(){
            return{
                DataArr:[
                ]
            }
        },
        methods:{
            // 返回上一个路由
            onClickLeft(){
                this.$router.go(-1);
            },
            // 获取公告
            RequestEvent(){
                let form = {
                    cmd:'200020',
                    uid:this.module.UIDEvent(),
                    language: "en",
                }
                REQUEST_API(form).then(r=>{
                    if (r.data.sc==0) { 
                        console.log(r.data.notifyList[0].data);
                        this.DataArr = JSON.parse(r.data.notifyList[0].data.notice);
                        this.DataArr.examineTime=this.module.timestampToTime(this.DataArr.examineTime);
                        console.log(JSON.parse(r.data.notifyList[0].data.notice));
                    }
                })
                .catch(e=>{
                    console.log(e)
                });
            }
        },
        created(){
            this.RequestEvent(); // 获取地区的
        }
    }
</script>

<style lang="less">
    #Announcement{
        width: 100%;
        height: 100%;
        >.content{
            position: absolute;
            top: 13.5rem;
            left: 50%;
            transform: translate(-50%);
            margin-top: -0.25rem;
            background-color: #f8f4f4;
            padding: 1.75rem 0.9375rem;
            width: calc(90% - 1.875rem);
            border-radius: 0.625rem;
            >.Text{
                width:calc(100% - 1.5rem);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                padding: 0.75rem;
                border-radius: 0.8rem;
                font-size: 0.875rem;
                margin-bottom:0.5625rem;
                >.text-icon{
                    margin: 0 0.5rem;
                }
            }
            >.content-item{
                padding: 0.75rem;
                display: flex;
                flex-direction: column;
                background-color: #fff;
                margin-bottom:0.5625rem;
                border-radius: 0.3rem;
                >span{
                    font-size: 14px;
                    line-height: 1.3rem;
                }
                >span:nth-child(1){
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: 1.7rem;
                }
            }
        }
    }
</style>